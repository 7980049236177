import { CSSProperties } from "react";
import { Switch, Typography, Space, Select } from "antd";

const { Text } = Typography;

export enum ITEM_TYPE {
    SWITCH,
    DROPDOWN
}

export interface DropdownItem {
    label: string,
    value: string | number
}

interface SettingsItemProps {
    label: string,
    sub?: string,
    type: ITEM_TYPE,
    onChange: (value: any) => void,
    items?: DropdownItem[],
    selected?: number | boolean
}

const SettingsItemStyle: CSSProperties = {
    borderRadius: 8
}

const SettingsItemFrame: CSSProperties = {
    background: '#FFFFFF22',
    borderRadius: 8,
    display: 'flex',
    padding: 16,
}

const SettingsItemLabel: CSSProperties = {
    flexGrow: 1,
    color: '#FFFFFF',
}

const SettingsTitleBold: CSSProperties = {
    fontWeight: 'bold'
}

const SettingsAction: CSSProperties = {
    alignContent: 'center'
}

const SettingsSelectDropdown: CSSProperties = {
    flexGrow: 1,
    display: 'flex'
}

const SelectDropdownLabel: CSSProperties = {
    fontSize: 10,
    fontWeight: 'bold',
    color: '#FFFFFF33',
    paddingLeft: 8
}

const getItemLayout = (type: ITEM_TYPE, label: string, sub: string | undefined, onChange: (value: any) => void, items: DropdownItem[] | undefined, selected: number | boolean | undefined) => {
    switch (type) {
        case ITEM_TYPE.SWITCH:
            return (
                <div style={SettingsItemFrame}>
                    <Space size={0} style={SettingsItemLabel} direction="vertical">
                        <Text style={SettingsTitleBold}>{label}</Text>
                        {sub && <Text type="secondary">{sub}</Text>}
                    </Space>
                    <div style={SettingsAction}>
                        <Switch value={typeof selected === "number" ? selected > 0 : selected} onChange={value => onChange(value)}/>
                    </div>
                </div>
            )
        case ITEM_TYPE.DROPDOWN:
            return (
            <div style={SettingsItemFrame}>
                <Space size={0} style={SettingsSelectDropdown} direction="vertical">
                    <Select value={selected} style={SettingsSelectDropdown} options={items?.map(item => {
                        return {
                            label: item.label,
                            value: item.value
                        }
                    })} onChange={value => onChange(value)}/>
                    <Text style={SelectDropdownLabel}>{label}</Text>
                </Space>
            </div>
            )
        default:
            return <div/>
    }
}

const SettingsItem = ({ label, sub, type, onChange, items, selected }: SettingsItemProps) => {
    return (
        <div style={SettingsItemStyle}>
            {getItemLayout(type, label, sub, onChange, items, selected)}
        </div>
    );
}

export default SettingsItem;