import React from "react";
import '../css/video-effect.css';

const Effects = ({ type }) => {
    const getStorageType = (type) => {
        switch(type) {
            case 1:
                return 'crt';
            default:
                return '';
        }
    }
    return (
        <div className={`video-effect ${getStorageType(type)}`}>

        </div>      
    )
};

export default Effects;