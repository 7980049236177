import { FC, CSSProperties, useState, ReactElement, useContext } from "react";
import { Space } from "antd";
import type { MenuProps } from "antd";
import SettingsItem, { ITEM_TYPE } from "./settings/SettingsItem";
import { DRAWER_DEFAULT, VIDEO_EFFECT } from "../providers/StorageProvider";
import { DropdownItem } from "./settings/SettingsItem";
import { StorageContext } from "../providers/StorageProvider";


const SettingsFrame: CSSProperties = {

};

const effectItems: MenuProps['items'] = [
    {
        label: (<span>None</span>),
        key: '0',
    },
    {
        label: (<span>CRT</span>),
        key: '1',
    }
];

const getEffectMenu = () => {
    const values = Object.keys(VIDEO_EFFECT).filter(key => isNaN(Number(key)));
    const items: DropdownItem[] = [];

    values.forEach(value => {
        items.push({
            label: value,
            value: values.indexOf(value)
        });
    });
    return items;
}

const Settings: FC = () => {
    const { storage, setStorage } = useContext(StorageContext);
    const [effectIdx, setEffectIdx] = useState<number>(0);

    return (
            <Space style={SettingsFrame} direction="vertical">
                <SettingsItem label={'Default Drawer Open'} sub={'Drawer is open by default when page reloads.'} type={ITEM_TYPE.SWITCH} onChange={value => {
                    const clonedStorage = {...storage};
                    storage.settings.drawerDefault = value ? 1 : 0;
                    setStorage(clonedStorage);
                }} selected={storage ? storage.settings.drawerDefault : DRAWER_DEFAULT.OPEN}/>
                <SettingsItem label={'Video Player Effect'} type={ITEM_TYPE.DROPDOWN} onChange={value => {
                    const clonedStorage = {...storage};
                    storage.settings.videoEffect = value;
                    setStorage(clonedStorage);
                }} items={getEffectMenu()} selected={storage ? storage.settings.videoEffect : VIDEO_EFFECT.NONE}/>
            </Space>
    )
};

export default Settings;