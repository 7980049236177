import { FC, CSSProperties } from "react";
import { UserInfo } from "../../declarations/UserEvent";
import { Tag } from "antd";

interface UserListProps {
    users: Array<UserInfo> | undefined
}

const UserList:FC<UserListProps> = ({ users }) => {
    const ListFrame: CSSProperties = {
        background: '#141414',
        borderRadius: 12,
        padding: 8,
        display: 'flex',
        gap: 8,
        flexDirection: 'column'
    }
    const ListItem: CSSProperties = {
        background: 'blue',
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'row',
        padding: '12px 8px',
        gap: 12
    }
    const ListItemDot: CSSProperties = {
        width: 16,
        height: 16,
        borderRadius: 8,
        justifySelf: 'center'
    }
    return (
        <div style={ListFrame}>
            {users?.map((user: UserInfo) => {
                return (
                    <div style={{...ListItem, ...{
                        background: `${user.color}33`
                    }}}>
                        <div style={{...ListItemDot, ...{background: user.color}}}/>
                        <div style={{
                            fontSize: 12,
                            color: '#FFF'
                        }}>
                            {user.name}
                        </div>
                    </div>
                )
            })}
        </div>
    );
}

export default UserList;