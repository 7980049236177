import { createContext, ReactElement, useState, useEffect } from 'react';
import { StorageContextType, StorageObjectType, StorageUser } from '../declarations/Storage';
import { LoadStorage, SaveStorage } from '../utils/Storage';

export enum VIDEO_EFFECT {
    NONE,
    CRT
}

export enum DRAWER_DEFAULT {
    OPEN,
    CLOSED
}

export const StorageContext = createContext<StorageContextType>({
    storage: {
        user: {
            name: 'Default User',
            color: '#FFFFFF'
        },
        settings: {
            playByDefault: false,
            drawerDefault: DRAWER_DEFAULT.OPEN,
            videoEffect: VIDEO_EFFECT.NONE
        }
    },
    setStorage: (storage: StorageObjectType) => {}
});

interface StorageProviderProps {
    children: ReactElement[] | ReactElement
}

export const StorageProvider = ({ children }: StorageProviderProps) => {
    const [storage, setStorage] = useState<StorageObjectType>();

    const value = {
        storage: storage as StorageObjectType,
        setStorage: (storage: StorageObjectType) => {
            setStorage(storage);
            SaveStorage(storage);
        }
    };

    // on initial load, get storage from browser
    useEffect(() => {
        initStorage();
    }, []);


    const initStorage = async () => {
        LoadStorage().then(storage => {
            if (storageNeedsUpdate(storage)) {
                updateStorage(storage);
            } else {
                const clonedStorage = {...storage};
                clonedStorage.settings = {...storage.settings};
                clonedStorage.user = {...storage.user};
                setStorage(clonedStorage);
                SaveStorage(clonedStorage);
            }
        });
    }

    const updateStorage = (storage: any) => {
        // check if the user storage is the old version or not, i.e will have a nickname field
        console.log('STORAGE ::: ', 'Updating user storage type to newest...');
        const updatedUser: StorageObjectType = {
            user: {
                name: storage['nickname'],
                color: storage['color']
            },
            settings: {
                playByDefault: false,
                drawerDefault: DRAWER_DEFAULT.OPEN,
                videoEffect: VIDEO_EFFECT.NONE
            }
        };
        SaveStorage(updatedUser);
    }

    const storageNeedsUpdate = (storage: any) => {
        return storage.hasOwnProperty('nickname');
    }

    return(
        <StorageContext.Provider value={value}>
            {children}
        </StorageContext.Provider>
    )
}