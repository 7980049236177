import { createContext, FC, ReactElement, useState, useEffect } from 'react';
import { ChatContextType, MessageItemInterface } from '../declarations/Chat';
import { UserInfo } from '../declarations/UserEvent';
import { SocketIO } from '../socketio';


const persistedMessages: MessageItemInterface[] = [];

export const ChatContext = createContext<ChatContextType>({
    user: undefined,
    setUser: (info: UserInfo) => {},
    users: undefined,
    setUsers: (users: Array<UserInfo>) => {},
    count: 0,
    nickname: undefined,
    setNickname: (name: string) => {},
    messages: []
});

interface ProviderProps {
    children: ReactElement[] | ReactElement
}

export const ChatProvider = ({ children }: ProviderProps) => {
    const [user, setUser] = useState<UserInfo | undefined>();
    const [users, setUsers] = useState<Array<UserInfo> | undefined>();
    const [count, setCount] = useState<number>(0);
    const [nickname, setNickname] = useState<string | undefined>();
    const [messages, setMessages] = useState<MessageItemInterface[]>(persistedMessages);

    useEffect(() => {
        SocketIO.on('message', onChatMessageRecieved);
        SocketIO.on('user_count', onUserCount);
        return () => {
            SocketIO.off('message', onChatMessageRecieved);
            SocketIO.off('user_count', onUserCount);
        }
    }, []);

    const onChatMessageRecieved = (message: MessageItemInterface) => {
        persistedMessages.push(message)
        setMessages([...persistedMessages, ...[]]);
    }

    const onUserCount = (count: number) => {
        setCount(count);
    }

    const onUserInfo = (info: UserInfo) => {
        setUser(info);
     }
    
    const value = {
        user, 
        setUser: (info: UserInfo) => {
            setUser(info);
        },
        users,
        setUsers: (users: Array<UserInfo>) => {
            setUsers(users);
        },
        count,
        nickname,
        setNickname: (name: string) => {
            setNickname(name);
        },
        messages
    }

    return (
        <ChatContext.Provider value={value}>
            {children}
        </ChatContext.Provider>
    )
}