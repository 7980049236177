import { FC, CSSProperties, useRef, useState, useEffect, useContext, forwardRef } from "react";
import { VIDEO_EFFECT, StorageContext } from "../providers/StorageProvider";
import VideoJS from "./VideoJS";
import Effects from "./Effects";

import "../css/videojs-styling.css";

interface PlayerProps {
    isMobile: boolean
}


const PlayerFrame: CSSProperties = {
    flexGrow: 5,
    position: 'relative',
    alignContent: 'center'
}

const HighlightEdge: CSSProperties = {
    border: 'solid 2px rgba(255, 255, 255, 0.1)',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 90,
    borderRadius: 8,
    pointerEvents: 'none'
}

const Player: FC<PlayerProps> = ({ isMobile }) => {
    const { storage } = useContext(StorageContext);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    return (
        <div style={PlayerFrame}>
            {!loading && 
            <VideoJS options={{
                autoplay: true,
                liveui: true,
                controls: true,
                fluid: true,
                sources: [{
                    src: 'http://104.131.178.74/hls/obs_stream.m3u8',
                    type: 'application/x-mpegURL'
                },{
                    src: 'http://104.131.178.74/dash/obs_stream.mpd',
                    type: 'application/dash+xml'
                }]
            }} onReady={() => {

            }}/>}
            <Effects type={storage ? storage.settings.videoEffect : VIDEO_EFFECT.NONE}/>
        </div>        
    );
};

export default Player;