import { FC, ReactElement, CSSProperties, useContext, useState, useEffect } from "react";
import { Button, Tabs } from 'antd';
import { DRAWER_DEFAULT, StorageContext } from "../providers/StorageProvider";
import { StorageObjectType } from "../declarations/Storage";

interface SidebarProps {
    isMobile: boolean,
    open: boolean,
    settingsComponent: ReactElement,
    chatComponent: ReactElement
}

const SidebarFrame: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: 16
}

const ClosedFrame: CSSProperties = {
    flexGrow: 0
}

const OpenFrame: CSSProperties = {
    flexGrow: 2
}

const TopFrame: CSSProperties = {
    padding: 12,
}

const TabsFrame: CSSProperties = {
    flex: 1
}

const Sidebar = ({ isMobile, open, settingsComponent, chatComponent }: SidebarProps) => {
    const { storage, setStorage } = useContext(StorageContext);
    const [ drawerOpen, setDrawerOpen ] = useState(false);
    const [ initialLoad, setInitialLoad ] = useState(false);

    useEffect(() => {
        if (storage && !initialLoad) {
            setDrawerOpen(storage.settings.drawerDefault > 0);
            setInitialLoad(true);
        }
    }, [storage]);

    const renderContent = () => {
        return (
            <div style={drawerOpen  ? {...SidebarFrame, ...OpenFrame} : {...SidebarFrame, ...ClosedFrame}}>
                {!drawerOpen &&
                <div style={TopFrame}>
                    <Button type="primary" onClick={() => {
                            setDrawerOpen(!drawerOpen)
                    }}>{drawerOpen ? 'Close' : 'Open'}</Button>
                </div>}
                {drawerOpen && <Tabs style={TabsFrame} tabBarExtraContent={{
                    right: (
                        <Button type="primary" onClick={() => {
                            setDrawerOpen(!drawerOpen)
                        }}>{drawerOpen ? 'Close' : 'Open'}</Button>
                    )
                }} items={[
                    {
                        key: 'chat',
                        label: 'Chat',
                        children: chatComponent,
                    },
                    {
                        key: 'settings',
                        label: 'Settings',
                        children: settingsComponent,
                    }
                ]}/>}
            </div>
        )
    }

    return (
        <>
            {storage ? renderContent() : <>testing</>}
        </>
    )
}

export default Sidebar;