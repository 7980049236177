import { FC, CSSProperties, useState, useEffect } from "react";
import { ConfigProvider, theme } from "antd";

import Player from "./components/Player";
import Chat from "./components/chat/Chat";
import Sidebar from "./components/Sidebar";
import { ChatProvider } from "./providers/ChatProvider";
import { StorageProvider } from "./providers/StorageProvider";
import Settings from "./components/Settings";


const MOBILE_BREAKPOINT: number = 600;
const isMobile: boolean = window.innerWidth < MOBILE_BREAKPOINT;

enum APP_TAB {
  CHAT,
  SETTINGS
}

const DesktopFrameStyling: CSSProperties = {

}
const MobileFrameStyling: CSSProperties = {
  width: '100%'
}
const LayoutStyling: CSSProperties = {
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0, 
  right: 0,
  display: 'flex'
}
const ContentStyling: CSSProperties = {

}
const SideStyling: CSSProperties = {
  display: 'flex'
}
const SideContent: CSSProperties = {
  display: 'flex',
  flexDirection: 'column'
}

const App: FC = () =>  {

  const renderSidebar = () => {
    return (
      <Sidebar isMobile={isMobile} open={true} settingsComponent={<Settings/>} chatComponent={<ChatProvider><Chat isMobile={isMobile}/></ChatProvider>}/>
    );
  }

  return (
    <StorageProvider>
      <ConfigProvider theme={{
        algorithm: theme.darkAlgorithm
      }}>
        <div style={LayoutStyling}>
          <Player isMobile={isMobile}/>
          {renderSidebar()}
        </div> 
      </ConfigProvider>    
    </StorageProvider>
  );
}

export default App;
