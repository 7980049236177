import { FC, CSSProperties, useState, useEffect, useContext, useMemo } from "react";
import EmojiPicker from "emoji-picker-react";
import { Theme } from 'emoji-picker-react';
import { Empty, Input, Button, Space, Flex, Divider, Typography, Avatar, Tag, ColorPicker, ColorPickerProps, GetProp, Popover } from "antd";
import { SmileFilled, UserOutlined, SettingTwoTone, SettingFilled, CloseOutlined, SaveFilled } from "@ant-design/icons";
import MessageList from "./MessageList";
import UserList from "./UserList";
import { SocketIO } from "../../socketio";
import { UserInfo } from "../../declarations/UserEvent";
import { ChatContext } from "../../providers/ChatProvider";
import { StorageContext } from "../../providers/StorageProvider";

type Color = GetProp<ColorPickerProps, 'value'>;
type Format = GetProp<ColorPickerProps, 'format'>;

const { Title, Paragraph, Text, Link } = Typography;

interface ChatProps {
    isMobile: boolean
}

const MobileFrame: CSSProperties = {
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0
}

const DesktopFrame: CSSProperties = {
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    gap: 8,
    width: '100%'
}



const ChatFrame: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
}

const ChatInputFrame: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    gap: 8
}

const ChatInput: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    padding: 8,
    background: '#141414',
    gap: 8,
    borderTopRightRadius: 12,
    borderTopLeftRadius: 12,
    flexGrow: 1,
    borderBottomRightRadius: 12,
    borderBottomLeftRadius: 12,
}



const MobileInfoStyling: CSSProperties = {

}

const DesktopInfoStyling: CSSProperties = {
    flexDirection: 'row',
    display: 'flex',
    gap: 8,
    padding: 8,
    background: '#141414',
    borderRadius: 12
}


const Chat: FC<ChatProps> = ({isMobile}) => {
    const [colorHex, setColorHex] = useState<Color>('#1677ff');
    const [formatHex, setFormatHex] = useState<Format | undefined>('hex');
    const hexString = useMemo<string>(
        () => (typeof colorHex === 'string' ? colorHex : colorHex?.toHexString()),
        [colorHex],
    );

    const { user, setUser, users, setUsers, messages } = useContext(ChatContext);
    const { storage, setStorage } = useContext(StorageContext);

    const [editingName, setEditingName] = useState<boolean>(false);
    const [editedName, setEditedName] = useState<string>(storage.user.name);
    const [newMessage, setNewMessage] = useState<string>('');
    const [showPreferences, setShowPreferences] = useState<boolean>(false);

    useEffect(() => {
        SocketIO.on('error', onConnectionError);
        SocketIO.on('user_info', onUserInfo);
        SocketIO.on('users_updated', onUserList);

        return () => {
            SocketIO.off('error', onConnectionError);
            SocketIO.off('user_info', onUserInfo);
            SocketIO.off('users_updated', onUserList);
        }
    }, []);

    useEffect(() => {
        setUserColor(hexString);
    }, [colorHex]);

    const onUserList = (users: Array<UserInfo>) => {
        setUsers(users);
    }
    
    const onUserInfo = async (info: UserInfo) => {

    }

    const setUserColor = (color: string, save: boolean = false) => {
        const clonedUser: UserInfo = {...user} as UserInfo;
        clonedUser.color = color;
        setUser(clonedUser);
        SocketIO.emit('update_user', clonedUser);
        if (save) {
            saveUserData();
        }
    }

    const saveUserData = () => {
        
    }

    const onSendMessage = () => {
        if (newMessage.length > 0) {
            SocketIO.emit('message',{
                name: editedName,
                type: 'message',
                message: newMessage,
                date: new Date().toDateString(),
                color: user?.color
            });
            setNewMessage('');
        }
    }

    const onConnectionError = (error: JSON) => {
        console.warn(error);
    }

    const getMessageScreen = () => {
        return (
            <div style={ChatFrame}>
                <MessageList messages = {messages}/>
                <div style={ChatInputFrame}>
                   {getMessageInput()}
                   {getSettingsSwitch()}
                </div>
            </div>
        )
    }

    const getMessageInput = () => {
        return (
            <div style={ChatInput}>
                <Input onPressEnter={onSendMessage} variant="filled" placeholder="Message" onChange={event => {
                    setNewMessage(event.currentTarget.value);
                }} value={newMessage}/>
                <Button disabled={newMessage.length === 0} onClick={onSendMessage} type="primary">
                    Send
                </Button>  
            </div>
        );
    }

    const getUserInfo = () => {
            return (
                <div>
                    <div style={isMobile ? MobileInfoStyling : DesktopInfoStyling}>
                        <ColorPicker
                        disabledAlpha={true}
                        disabled={!editingName}
                        format={formatHex}
                        value={colorHex}
                        onChange={color => {
                            setColorHex(color);
                        }}
                        onFormatChange={setFormatHex}
                        />
                        <Input onChange={(event) => {
                            setEditedName(event.currentTarget.value);
                        }} value={editedName} status={editedName.length > 0 ? '' : 'error'} disabled={!editingName}/>
                        <Button disabled={editedName.length === 0} onClick={() => {
                            saveUserData();
                            setEditingName(!editingName);
                        }} type="primary">{editingName ? 'Save' : 'Edit'}</Button>
                    </div>
                </div>
            )            
    }

    const getUserList = () => {
        return <UserList users={users}/>
    }

    const getSettingsSwitch = () => {
        const SettingsFrame: CSSProperties = {
            padding: 8,
            background: '#141414',
            borderRadius: 12
        }
        return (
            <div style={SettingsFrame}>
                <Button onClick={() => {
                    setShowPreferences(!showPreferences);
                }} type="primary" icon={showPreferences ? <SaveFilled/> : <SettingFilled/>}/>
            </div>
        );
    }

    return (
        <div style={isMobile ? MobileFrame : DesktopFrame}>
            {getMessageScreen()}
            {showPreferences && getUserList()}
            {showPreferences && getUserInfo()}
        </div>
    );
};

export default Chat;